import { ChangeEvent, forwardRef, Ref } from 'react'

export type Props = {
  label: string
  placeholder: string
  value: string
  onChange: (value: string) => void
}

function InputRow(props: Props, ref: Ref<HTMLInputElement>) {
  const { label, placeholder, value, onChange } = props

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <label className="flex align-baseline">
      <span className="flex-none w-24 pr-2 text-right">{label}:</span>
      <input
        className="flex-1 pb-px border-b-2 font-mono focus:border-accent-500 bg-transparent rounded-none outline-none transition-colors"
        type="text"
        autoCorrect="off"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        ref={ref}
      />
    </label>
  )
}

export default forwardRef(InputRow)
