import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import AppState, { initialState } from '../types/AppState'

const AppStateValueContext = createContext<AppState>(initialState)
const SetAppStateContext = createContext<Dispatch<SetStateAction<AppState>>>(
  () => {
    throw new Error(
      'setAppState called out side of AppStateProvider component tree',
    )
  },
)

export function AppStateProvider({ children }: PropsWithChildren<{}>) {
  const [appState, setAppState] = useState(initialState)

  return (
    <SetAppStateContext.Provider value={setAppState}>
      <AppStateValueContext.Provider value={appState}>
        {children}
      </AppStateValueContext.Provider>
    </SetAppStateContext.Provider>
  )
}

export function useAppStateValue() {
  return useContext(AppStateValueContext)
}

export function useSetAppState() {
  return useContext(SetAppStateContext)
}

export function useAppState() {
  return [useAppStateValue(), useSetAppState()] as const
}
