import { useCallback, useEffect, useRef, useState } from 'react'
import '../soundcloud-widget-api'
import { assertState, runningMovingAverage } from '../utils'
import socket from '../socket'
import PositionWithTimestamp from '../types/PositionWithTimestamp'
import useSocket from '../hooks/useSocket'
import { useDebug } from '../contexts/Debug'
import { useAppState } from '../contexts/AppState'
import LabeledCheckbox from './LabeledCheckbox'
import Button from './Button'
import SoundCloudIFrame from './SoundCloudIFrame'
import TrackUrlForm from './TrackUrlForm'
import FormSubmitButton from './FormSubmitButton'

const DELAY_SAMPLES = 8
const CALIBRATION_THRESHOLD = 5
const SYNC_INTERVAL = 1000
const UPDATE_DELAY_TIMEOUT = 2000
const SYNC_TIMEOUT = 5000
const WIDGET_BIND_TIMEOUT = 1000

const TOLERANCE_OPTIONS = [0.025, 0.05, 0.1, 0.5, 1, 5]

export default function SoundCloudPlayer() {
  const debug = useDebug()
  const { open, send } = useSocket(socket)
  const [{ masterPosition, room }, setAppState] = useAppState()

  assertState(room.state, 'joined', 'room')
  assertState(room.track.state, 'set', 'track')

  const [disablePlayer, setDisablePlayer] = useState(true)
  const [playbackStarted, setPlaybackStarted] = useState(false)
  const [autoSyncEnabled, setAutoSyncEnabled] = useState(true)
  const [tolerance, setTolerance] = useState(TOLERANCE_OPTIONS[2])
  const [showTrackUrlForm, setShowTrackUrlForm] = useState(false)

  const shouldUpdateDelayRef = useRef(true)
  const shouldSyncRef = useRef(true)
  const manualAutoSyncRef = useRef(autoSyncEnabled)
  const masterPositionRef = useRef<PositionWithTimestamp | null>(masterPosition)
  const currentPositionRef = useRef<PositionWithTimestamp | null>(null)
  const delayRef = useRef<number | null>(null)
  const calibrationRef = useRef(0)
  const playerRef = useRef<HTMLIFrameElement>(null)
  const scWidgetRef = useRef<any>(null)

  const resetDelay = useCallback(() => {
    delayRef.current = null
    shouldUpdateDelayRef.current = false
    shouldSyncRef.current = false
    setTimeout(() => {
      shouldUpdateDelayRef.current = true
    }, UPDATE_DELAY_TIMEOUT)
    setTimeout(() => {
      shouldSyncRef.current = true
    }, SYNC_TIMEOUT)
  }, [])

  const sync = useCallback(() => {
    const scWidget = scWidgetRef.current
    const currentPosition = currentPositionRef.current!
    const delay = delayRef.current!

    if (Math.abs(delay) > tolerance) {
      calibrationRef.current += delay

      if (Math.abs(calibrationRef.current) >= CALIBRATION_THRESHOLD) {
        calibrationRef.current = 0
      }

      scWidget.seekTo(
        (adjustToNow(currentPosition) + delay + calibrationRef.current) * 1000,
      )
      resetDelay()
    }
  }, [tolerance, resetDelay])

  const decreaseTolerance = useCallback(() => {
    setTolerance((current) => {
      const currentIndex = TOLERANCE_OPTIONS.indexOf(current)
      if (currentIndex === -1) {
        throw new Error(`Unexpected tolerance: ${current}`)
      }
      if (currentIndex === 0) return current
      return TOLERANCE_OPTIONS[currentIndex - 1]
    })
  }, [])

  const increaseTolerance = useCallback(() => {
    setTolerance((current) => {
      const currentIndex = TOLERANCE_OPTIONS.indexOf(current)
      if (currentIndex === -1) {
        throw new Error(`Unexpected tolerance: ${current}`)
      }
      if (currentIndex === TOLERANCE_OPTIONS.length - 1) return current
      return TOLERANCE_OPTIONS[currentIndex + 1]
    })
  }, [])

  useEffect(() => {
    manualAutoSyncRef.current = autoSyncEnabled
  }, [autoSyncEnabled])

  useEffect(() => {
    masterPositionRef.current = masterPosition
  }, [masterPosition])

  useEffect(() => {
    setPlaybackStarted(false)
    scWidgetRef.current = SC.Widget(playerRef.current)
  }, [room.track.url])

  useEffect(() => {
    setTimeout(() => {
      scWidgetRef.current.getCurrentSound((sound: any) => {
        setAppState((prevState) => {
          assertState(prevState.room.state, 'joined', 'room')
          assertState(prevState.room.track.state, 'set', 'track')

          return {
            ...prevState,
            room: {
              ...prevState.room,
              track: { ...prevState.room.track, title: sound.title },
            },
          }
        })
      })
    }, WIDGET_BIND_TIMEOUT)
  }, [room.track.url, setAppState])

  useEffect(() => {
    if (!open || playbackStarted) return

    const scWidget = scWidgetRef.current

    setTimeout(() => {
      scWidget.bind(SC.Widget.Events.PLAY, () => {
        send!({ type: 'player:start_playback', payload: {} })
        setPlaybackStarted(true)
      })
    }, WIDGET_BIND_TIMEOUT)

    return () => {
      try {
        scWidget.unbind(SC.Widget.Events.PLAY)
      } catch (error) {
        console.warn('Could not unbind SC.Widget.Events.PLAY:', error)
      }
    }
  }, [open, send, playbackStarted, room.track.url])

  useEffect(() => {
    const scWidget = scWidgetRef.current

    setTimeout(() => {
      scWidget.bind(
        SC.Widget.Events.PLAY_PROGRESS,
        ({ currentPosition }: any) => {
          currentPositionRef.current = {
            position: currentPosition / 1000,
            timestamp: performance.now(),
          }
        },
      )
      setDisablePlayer(false)
    }, WIDGET_BIND_TIMEOUT)

    return () => {
      setDisablePlayer(true)
      currentPositionRef.current = null
      resetDelay()
      try {
        scWidget.unbind(SC.Widget.Events.PLAY_PROGRESS)
      } catch (error) {
        console.warn('Could not unbind SC.Widget.Events.PLAY_PROGRESS:', error)
      }
    }
  }, [resetDelay, room.track.url])

  useEffect(() => {
    const id = setInterval(() => {
      const shouldSync = shouldSyncRef.current
      const manualAutoSync = manualAutoSyncRef.current
      const shouldUpdateDelay = shouldUpdateDelayRef.current
      const masterPosition = masterPositionRef.current
      const currentPosition = currentPositionRef.current

      if (
        !shouldUpdateDelay ||
        masterPosition === null ||
        currentPosition === null
      )
        return

      const currentDelay =
        adjustToNow(masterPosition) - adjustToNow(currentPosition)

      delayRef.current = runningMovingAverage(
        currentDelay,
        delayRef.current,
        DELAY_SAMPLES,
      )

      if (manualAutoSync && shouldSync) sync()
    }, SYNC_INTERVAL)

    return () => clearInterval(id)
  }, [sync])

  const handleAutoSyncCheckboxChange = () =>
    setAutoSyncEnabled((value) => !value)

  const updateMasterPosition = () => {
    if (!open || currentPositionRef.current === null) return

    send!({
      type: 'player:submit_position',
      payload: { position: adjustToNow(currentPositionRef.current) },
    })
    resetDelay()
  }

  const toggleShowTrackUrlForm = () => setShowTrackUrlForm((value) => !value)

  return (
    <div
      className={`flex flex-col space-y-3 ${
        disablePlayer ? 'pointer-events-none' : ''
      }`}
    >
      <SoundCloudIFrame trackUrl={room.track.url} ref={playerRef} />
      <div className="flex items-center space-x-3">
        <div className="flex-1">
          <Button
            onClick={sync}
            disabled={autoSyncEnabled || !shouldSyncRef.current}
          >
            Sync
            {delayRef.current !== null && (
              <>
                {' '}
                <span className="text-xs">
                  ({formatDelay(delayRef.current)})
                </span>
              </>
            )}
          </Button>
        </div>
        <div className="flex-none">
          <LabeledCheckbox
            label="Auto sync"
            checked={autoSyncEnabled}
            onChange={handleAutoSyncCheckboxChange}
          />
        </div>
      </div>
      <div className="w-full px-2 py-1 flex flex-col rounded-sm shadow bg-accent-500">
        <div className="text-xs text-center">
          Sync tolerance: &plusmn;{formatTime(tolerance)}
        </div>
        <div className="flex items-center h-8">
          <button
            className="flex-1 text-center text-xl rounded-l-sm focus:outline-none active:bg-accent-700 text-white disabled:text-opacity-50 disabled:cursor-not-allowed transition-colors border-r border-accent-700"
            onClick={decreaseTolerance}
            disabled={tolerance === TOLERANCE_OPTIONS[0]}
          >
            &minus;
          </button>
          <button
            className="flex-1 text-center text-xl rounded-r-sm focus:outline-none active:bg-accent-700 text-white disabled:text-opacity-50 disabled:cursor-not-allowed transition-colors"
            onClick={increaseTolerance}
            disabled={
              tolerance === TOLERANCE_OPTIONS[TOLERANCE_OPTIONS.length - 1]
            }
          >
            +
          </button>
        </div>
      </div>

      {!autoSyncEnabled && currentPositionRef.current && (
        <div className="space-y-1">
          <Button onClick={updateMasterPosition}>
            <p>
              Update position to{' '}
              <code>
                {formatPosition(adjustToNow(currentPositionRef.current))}
              </code>
            </p>
            <p className="text-xs">
              This will update the position for everyone in the room.
            </p>
          </Button>
        </div>
      )}
      {!showTrackUrlForm ? (
        <>
          <Button variant="outline" onClick={toggleShowTrackUrlForm}>
            Change Track
          </Button>
          {tolerance < 1 && (
            <p className="text-xs text-gray-400">
              Auto sync is attempting to get the SoundCloud player within{' '}
              <span className="text-white">
                &plusmn;{formatTime(tolerance)}
              </span>{' '}
              of the shared track position. This process can take up to a
              minute, especially on mobile devices. If your device continues to
              fail to sync, try refreshing the page, increasing the sync
              tolerance or disabling auto sync.
            </p>
          )}
        </>
      ) : (
        <>
          <TrackUrlForm />
          <FormSubmitButton variant="outline" onClick={toggleShowTrackUrlForm}>
            Cancel
          </FormSubmitButton>
        </>
      )}
      {debug && (
        <p className="text-gray-400 font-mono">
          Calibration: {calibrationRef.current?.toFixed(3)}
        </p>
      )}
    </div>
  )
}

function adjustToNow({ position, timestamp }: PositionWithTimestamp): number {
  return position + (performance.now() - timestamp) / 1000
}

function formatTime(seconds: number) {
  return seconds < 1
    ? `${Math.round(seconds * 1000)}ms`
    : `${seconds.toFixed(2)}s`
}

function formatDelay(delay: number) {
  const label = delay > 0 ? 'behind' : 'ahead'
  const absDelay = Math.abs(delay)
  const delayString = formatTime(absDelay)
  return `${delayString} ${label}`
}

function formatPosition(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  seconds %= 3600
  const minutes = Math.floor(seconds / 60)
  seconds %= 60
  seconds = Math.floor(seconds)

  return [hours, minutes, seconds]
    .flatMap((value) => (value > 0 ? [value.toString().padStart(2, '0')] : []))
    .join(':')
    .padStart(5, '00:')
}
