import { useAppStateValue } from '../contexts/AppState'
import { ReactComponent as ShareIcon } from '../icons/share.svg'

export default function RoomIdIndicator() {
  const { room } = useAppStateValue()

  if (room.state !== 'joined') return null

  const canShare = 'share' in navigator

  const handleShare = () => {
    if (canShare) {
      const trackTitle =
        room.state === 'joined' && room.track.state === 'set'
          ? room.track.title
          : 'SoundCloud sets'

      navigator.share({
        title: document.title,
        text: `Listen to ${trackTitle} in sync with your friends`,
        url: window.location.href,
      })
    }
  }

  return (
    <div
      className={`flex-none flex items-center space-x-1 ${
        canShare ? 'hover:text-accent-500 cursor-pointer transition-colors' : ''
      }`}
      onClick={handleShare}
    >
      <span>
        Room ID: <code>{room.id}</code>{' '}
      </span>
      {canShare && <ShareIcon className="h-4 inline-block fill-current" />}
    </div>
  )
}
