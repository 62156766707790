import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

const DebugContext = createContext(false)

export function DebugProvider({ children }: PropsWithChildren<{}>) {
  const [debug, setDebug] = useState(false)

  useEffect(() => {
    const url = new URL(window.location.href)
    setDebug(url.searchParams.has('debug'))
  }, [])

  return <DebugContext.Provider value={debug}>{children}</DebugContext.Provider>
}

export function useDebug() {
  return useContext(DebugContext)
}
