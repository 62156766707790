import { ReactComponent as Tick } from '../icons/tick.svg'

export type Props = {
  label: string
  checked: boolean
  onChange: () => void
}

export default function LabeledCheckbox({ label, checked, onChange }: Props) {
  return (
    <label className="flex items-center">
      <input
        type="checkbox"
        className="hidden"
        checked={checked}
        onChange={onChange}
      />
      <div
        className={`h-5 w-5 mr-2 border rounded-sm shadow transition-colors cursor-pointer ${
          checked ? 'bg-accent-500 border-transparent' : 'bg-transparent'
        }`}
      >
        <Tick
          className={`fill-current transition-opacity ${
            checked ? '' : 'opacity-0'
          }`}
        />
      </div>
      {label}
    </label>
  )
}
