import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { DebugProvider } from './contexts/Debug'
import { AppStateProvider } from './contexts/AppState'
import MessageHandler from './components/MessageHandler'
import Pinger from './components/Pinger'
import App from './components/App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <DebugProvider>
      <AppStateProvider>
        <MessageHandler />
        <Pinger />
        <App />
      </AppStateProvider>
    </DebugProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
