export function ensureNever(value: never): any {
  return value
}

export function assertState<S extends string, T extends S>(
  actualState: S,
  expectedState: T,
  label: string,
): asserts actualState is T {
  if (actualState !== expectedState) {
    throw new Error(`Unexpected ${label} state: ${actualState}`)
  }
}

export function runningMovingAverage(
  value: number,
  average: number | null,
  n: number,
) {
  if (average === null) return value
  return ((n - 1) * average + value) / n
}
