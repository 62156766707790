import { FormEvent, useState } from 'react'
import { assertState } from '../utils'
import socket from '../socket'
import useSocket from '../hooks/useSocket'
import FormSubmitButton from './FormSubmitButton'
import InputRow from './InputRow'
import { useSetAppState } from '../contexts/AppState'

const SC_URL_REGEX =
  /^(https?:\/\/)?(((www|m)\.)?soundcloud\.com|soundcloud\.app\.goo\.gl)\/.+$/

export default function TrackUrlForm() {
  const { open, send } = useSocket(socket)
  const setAppState = useSetAppState()
  const [trackUrl, setTrackUrl] = useState('')

  const handleTrackUrlChange = (value: string) => {
    setTrackUrl(value.trim())
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (!open || !formValid) return
    send!({ type: 'player:set_soundcloud_url', payload: { url: trackUrl } })
    setAppState((prevState) => {
      assertState(prevState.room.state, 'joined', 'room')

      return {
        ...prevState,
        room: { ...prevState.room, track: { state: 'setting' } },
      }
    })
  }

  const formValid = SC_URL_REGEX.test(trackUrl)

  const disableForm = !open

  return (
    <form className="space-y-3" onSubmit={handleSubmit}>
      <fieldset
        className="space-y-2 disabled:opacity-50"
        disabled={disableForm}
      >
        <InputRow
          label="Track URL"
          placeholder="https://soundcloud.com/…"
          value={trackUrl}
          onChange={handleTrackUrlChange}
        />
        <p className="ml-24 text-xs text-gray-400">
          Make sure to paste the URL of a track or a set as opposed to a
          playlist or a stream.
        </p>
      </fieldset>
      <FormSubmitButton disabled={disableForm || !formValid}>
        Set track URL
      </FormSubmitButton>
    </form>
  )
}
