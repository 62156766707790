import Button, { Props as ButtonProps } from './Button'

export type Props = ButtonProps

export default function FormSubmitButton(buttonProps: Props) {
  return (
    <div className="mx-24">
      <Button {...buttonProps} />
    </div>
  )
}
