import { forwardRef, Ref } from 'react'

export type Props = {
  trackUrl: string
}

const BASE_URL = 'https://w.soundcloud.com/player/'

function SoundCloudIFrame({ trackUrl }: Props, ref: Ref<HTMLIFrameElement>) {
  const url = new URL(BASE_URL)

  const searchParams: [string, string][] = [
    ['url', trackUrl],
    ['color', '#f97316'],
    ['auto_play', 'false'],
    ['hide_related', 'false'],
    ['show_comments', 'false'],
    ['show_user', 'false'],
    ['show_reposts', 'false'],
    ['show_teaser', 'false'],
    ['visual', 'true'],
  ]

  searchParams.forEach(([key, value]) => url.searchParams.set(key, value))

  return (
    <iframe
      title="SoundCloud Player"
      width="100%"
      height="300"
      scrolling="no"
      frameBorder="no"
      src={url.href}
      ref={ref}
    />
  )
}

export default forwardRef(SoundCloudIFrame)
