import { useEffect } from 'react'
import { useDebug } from '../contexts/Debug'
import { useAppStateValue } from '../contexts/AppState'
import RoomIdIndicator from './RoomIdIndicator'
import RoomJoinForm from './RoomJoinForm'
import TrackUrlForm from './TrackUrlForm'
import SoundCloudPlayer from './SoundCloudPlayer'

export default function App() {
  const debug = useDebug()
  const { error, room, averagePing } = useAppStateValue()

  useEffect(() => {
    if (room.state !== 'joined') return
    const url = new URL(window.location.href)
    url.searchParams.set('room', room.id)
    window.history.replaceState({}, '', url.href)
  }, [room])

  return (
    <div className="absolute top-0 h-full w-full sm:py-3">
      <div className="flex flex-col mx-auto px-6 py-3 w-full h-full max-w-md space-y-3 bg-gray-900 sm:rounded shadow-lg overflow-scroll">
        <header className="flex-none flex flex-wrap items-baseline">
          <h1 className="flex-1 text-2xl font-display mr-2">Synchrodisco</h1>
          <RoomIdIndicator />
        </header>
        {error && (
          <p className="px-2 py-1 bg-accent-900 rounded-sm shadow">{error}</p>
        )}
        <main className="flex-1">
          {room.state !== 'joined' ? (
            <RoomJoinForm />
          ) : room.track.state !== 'set' ? (
            <TrackUrlForm />
          ) : (
            <SoundCloudPlayer />
          )}
        </main>
        {debug && (
          <p className="text-gray-400 font-mono">
            Ping: {averagePing?.toFixed(3)}
          </p>
        )}
      </div>
    </div>
  )
}
