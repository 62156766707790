import { assertState, ensureNever, runningMovingAverage } from '../utils'
import socket from '../socket'
import { useSetAppState } from '../contexts/AppState'
import useSocket from '../hooks/useSocket'

const PING_SAMPLES = 8

export default function MessageHandler() {
  const setAppState = useSetAppState()

  useSocket(socket, (message) => {
    switch (message.type) {
      case 'pong':
        setAppState((prevState) => {
          const { lastPingAt, averagePing: prevAveragePing } = prevState
          if (lastPingAt === null) return prevState

          const currentPing = performance.now() - lastPingAt
          const averagePing = runningMovingAverage(
            currentPing,
            prevAveragePing,
            PING_SAMPLES,
          )

          return {
            ...prevState,
            lastPingAt: null,
            averagePing,
          }
        })
        break
      case 'room:join':
        if (message.payload.error !== null) {
          setAppState((prevState) => ({
            ...prevState,
            error: message.payload.error,
            room: { state: 'failed' },
          }))
          break
        }

        setAppState((prevState) => {
          assertState(prevState.room.state, 'joining', 'room')

          const trackUrl = message.payload.soundcloud_url

          return {
            ...prevState,
            error: null,
            room: {
              state: 'joined',
              id: prevState.room.id,
              track:
                trackUrl === null
                  ? { state: 'unset' }
                  : { state: 'set', url: trackUrl, title: null },
            },
          }
        })
        break
      case 'player:set_soundcloud_url':
        setAppState((prevState) => {
          assertState(prevState.room.state, 'joined', 'room')

          return {
            ...prevState,
            room: {
              ...prevState.room,
              track: { state: 'set', url: message.payload.url, title: null },
            },
          }
        })
        break
      case 'player:start_playback':
        // TODO: One day, we will care about this message
        break
      case 'player:position_update':
        setAppState((prevState) => ({
          ...prevState,
          masterPosition: {
            position: message.payload.position,
            timestamp: performance.now() - (prevState.averagePing ?? 0) / 2,
          },
        }))
        break
      default:
        throw new Error(
          `Unexpected message type: '${ensureNever(message).type}'`,
        )
    }
  })

  return null
}
