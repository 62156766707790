import { PropsWithChildren } from 'react'

export type Props = PropsWithChildren<{
  onClick?: () => void
  disabled?: boolean
  variant?: 'solid' | 'outline'
}>

export default function Button({
  onClick,
  disabled,
  variant = 'solid',
  children,
}: Props) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`w-full px-2 py-1 rounded-sm shadow focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed transition-colors ${
        variant === 'solid'
          ? 'bg-accent-500 active:bg-accent-700'
          : variant === 'outline'
          ? 'border hover:border-accent-500 active:text-accent-500'
          : throwInvalidVariantError(variant)
      }`}
    >
      {children}
    </button>
  )
}

function throwInvalidVariantError(value: never): never {
  throw new Error(`Invalid variant: ${value}`)
}
