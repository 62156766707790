import { useEffect } from 'react'
import socket from '../socket'
import { useSetAppState } from '../contexts/AppState'
import useSocket from '../hooks/useSocket'

const PING_INTERVAL = 1000

export default function Pinger() {
  const setAppState = useSetAppState()
  const { open, send } = useSocket(socket)

  useEffect(() => {
    if (!open) return

    const id = setInterval(() => {
      send!({ type: 'ping', payload: {} })
      setAppState((prevState) =>
        prevState.lastPingAt === null
          ? { ...prevState, lastPingAt: performance.now() }
          : prevState,
      )
    }, PING_INTERVAL)

    return () => clearInterval(id)
  }, [open, send, setAppState])

  return null
}
