import PositionWithTimestamp from './PositionWithTimestamp'

type AppState = {
  error: string | null
  lastPingAt: number | null
  averagePing: number | null
  masterPosition: PositionWithTimestamp | null
  room: RoomState
}

type RoomState =
  | { state: 'initial' | 'failed' }
  | { state: 'joining'; id: string }
  | { state: 'joined'; id: string; track: TrackState }

type TrackState =
  | { state: 'unset' | 'setting' }
  | { state: 'set'; url: string; title: string | null }

export const initialState: AppState = {
  error: null,
  lastPingAt: null,
  averagePing: null,
  masterPosition: null,
  room: { state: 'initial' },
}

export default AppState
