import { FormEvent, useEffect, useRef, useState } from 'react'
import socket from '../socket'
import useSocket from '../hooks/useSocket'
import { useAppState } from '../contexts/AppState'
import InputRow from './InputRow'
import Spinner from './Spinner'
import FormSubmitButton from './FormSubmitButton'

const FORM_VALUE_REGEX = /^[A-Z]{0,12}$/

export default function RoomJoinForm() {
  const { open, send } = useSocket(socket)
  const [{ room }, setAppState] = useAppState()
  const [username, setUsername] = useState('')
  const [roomId, setRoomId] = useState('')

  const usernameInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const url = new URL(window.location.href)
    setRoomId(url.searchParams.get('room') ?? '')
  }, [])

  useEffect(() => {
    if (room.state === 'failed') {
      setUsername('')
      usernameInputRef.current?.focus()
    }
  }, [room])

  const isLoading = room.state === 'joining'

  const formValid = [username, roomId].every(
    (value) => value.length >= 4 && FORM_VALUE_REGEX.test(value),
  )

  const disableForm = !open || isLoading

  const handleUsernameChange = sanitizeAndSetWith(setUsername)
  const handleRoomIdChange = sanitizeAndSetWith(setRoomId)

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (!open || !formValid) return
    send!({ type: 'room:join', payload: { username, room_id: roomId } })
    setAppState((prevState) => ({
      ...prevState,
      room: { state: 'joining', id: roomId },
    }))
  }

  return (
    <form className="space-y-3" onSubmit={handleSubmit}>
      <fieldset
        className="space-y-2 disabled:opacity-50"
        disabled={disableForm}
      >
        <InputRow
          label="Username"
          placeholder="4 to 12 characters"
          value={username}
          onChange={handleUsernameChange}
          ref={usernameInputRef}
        />
        <InputRow
          label="Room ID"
          placeholder="4 to 12 characters"
          value={roomId}
          onChange={handleRoomIdChange}
        />
      </fieldset>
      <FormSubmitButton disabled={disableForm || !formValid}>
        {isLoading ? <Spinner /> : 'Join Room'}
      </FormSubmitButton>
      <div className="px-3 py-2 space-y-1 text-xs bg-gray-800 rounded-sm shadow">
        <p>
          This website lets you listen to Soundcloud sets in sync with your
          friends. To get started, pick a username and a room ID. The room will
          be created if it doesn't exist. You can then share the URL in the
          address bar to let your friends join.
        </p>
        <p>
          The username is only used to identify your connection. No personal
          data will be collected. This website also doesn't use any cookies.
        </p>
      </div>
    </form>
  )
}

function sanitizeAndSetWith(
  setValue: (value: string) => void,
): (value: string) => void {
  return (value) => {
    const sanitized = value.trim().toUpperCase()
    if (FORM_VALUE_REGEX.test(sanitized)) {
      setValue(sanitized)
    }
  }
}
