import { useEffect, useState } from 'react'
import { InMessage, OutMessage } from '../types/messages'

export type Hook =
  | { open: true; send: (message: OutMessage) => void }
  | { open: false; send: null }

export default function useSocket(
  socket: WebSocket,
  onMessage?: (message: InMessage) => void,
): Hook {
  const [hook, setHook] = useState<Hook>(hookFromSocket(socket))

  useEffect(() => {
    const listener = () => setHook(hookFromSocket(socket))

    socket.addEventListener('open', listener)
    socket.addEventListener('close', listener)
    socket.addEventListener('error', listener)

    return () => {
      socket.removeEventListener('open', listener)
      socket.removeEventListener('close', listener)
      socket.removeEventListener('error', listener)
    }
  }, [socket])

  useEffect(() => {
    const listener = (event: MessageEvent<string>) =>
      onMessage?.(JSON.parse(event.data))

    socket.addEventListener('message', listener)

    return () => {
      socket.removeEventListener('message', listener)
    }
  }, [socket, onMessage])

  return hook
}

function hookFromSocket(socket: WebSocket): Hook {
  switch (socket.readyState) {
    case WebSocket.OPEN:
      return {
        open: true,
        send: (message: OutMessage) => socket.send(JSON.stringify(message)),
      }
    default:
      return { open: false, send: null }
  }
}
